/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"

export default function SectionTitle({ text }) {
  return (
    <caption
      sx={{
        my: 7,
        color: `text.header`,
        fontFamily: `heading`,
        fontWeight: `heading`,
        fontSize: 4,
        textAlign: `left`,
      }}
    >
      {text}
    </caption>
  )
}

SectionTitle.propTypes = {
  text: PropTypes.string,
}
