/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState } from "react"
import SectionTitle from "./evaluation-table-section-title"
import SectionHeaderTop from "./evaluation-table-section-header-top"
import { renderCell } from "./evaluation-cell"

const tdStyles = {
  display: `table-cell`,
  "&:hover": {
    cursor: `pointer`,
  },
  minWidth: 40,
  px: 0,
  textAlign: `left`,
  verticalAlign: `middle`,
  fontSize: 1,
  lineHeight: `solid`,
}

export default function SimpleEvaluationTable(props) {
  const { title, headers, data } = props
  const [featureCell, setFeatureCell] = useState({})
  const showTooltip = row => featureCell[`feature-cell-${row}`]

  return (
    <table>
      {title && <SectionTitle text={title} />}
      <tbody>
        <SectionHeaderTop columnHeaders={headers.map(h => h.display)} />
        {data.map((node, idx) =>
          [].concat([
            <tr key={`feature-first-row-${idx}`}>
              {headers.map((header, i) => {
                if (i === 0) {
                  return (
                    <th
                      key={`feature-cell-${idx}-${i}`}
                      scope="row"
                      sx={{
                        ...tdStyles,
                        borderBottomWidth: !showTooltip(idx) ? 1 : 0,
                        borderBottomStyle: `solid`,
                        borderBottomColor: `standardLine`,
                        textAlign: i === 0 ? `left` : `center`,
                        "&:hover": {
                          cursor: `inherit`,
                        },
                      }}
                    >
                      <button
                        onClick={() => {
                          setFeatureCell({
                            ...featureCell,
                            [`feature-cell-${idx}`]: !showTooltip(idx),
                          })
                        }}
                        sx={{
                          "&:hover": {
                            cursor: `pointer`,
                          },
                          // override default button styles
                          background: `none`,
                          border: `none`,
                          appearance: `none`,
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {renderCell(node[header.nodeFieldProperty], i)}
                      </button>
                    </th>
                  )
                }

                return (
                  <td
                    key={`feature-cell-${idx}-${i}`}
                    sx={{
                      ...tdStyles,
                      borderBottomWidth: !showTooltip(idx) ? 1 : 0,
                      borderBottomStyle: `solid`,
                      borderBottomColor: `standardLine`,
                      textAlign: i === 0 ? `left` : `center`,
                      "&:hover": {
                        cursor: `inherit`,
                      },
                    }}
                  >
                    {renderCell(node[header.nodeFieldProperty], i)}
                  </td>
                )
              })}
            </tr>,
            // table row containing details of each feature
            <tr
              sx={{
                display: showTooltip(idx) ? `table-row` : `none`,
              }}
              key={`feature-second-row-${idx}`}
            >
              <td
                sx={{
                  pb: t => `calc(${t.space[6]} - 1px)`,
                  "&&": {
                    px: [null, 4],
                  },
                }}
                colSpan="5"
              >
                {
                  <span
                    dangerouslySetInnerHTML={{
                      __html: node.Description,
                    }}
                  />
                }
              </td>
            </tr>,
          ])
        )}
      </tbody>
    </table>
  )
}
