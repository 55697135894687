/** @jsx jsx */
import { jsx } from "theme-ui"

import EvaluationCell from "./evaluation-cell"
import { iconTextDictionary } from "./icon-text-dictionary"

const legendTableCaptionStyle = {
  fontWeight: `body`,
  letterSpacing: `tracked`,
  textTransform: `uppercase`,
  textAlign: `left`,
  marginBottom: `1.5rem`,
  lineHeight: `1.5rem`,
}

const legendBallStyle = {
  float: `none`,
  display: `inline-block`,
  mx: 0,
}

const baseCellStyle = {
  verticalAlign: `middle`,
  textAlign: `center`,
  p: 4,
  borderWidth: 1,
  borderStyle: `solid`,
  borderColor: `standardLine`,
}

const legendTableContainerStyle = {
  borderColor: `standardLine`,
  fontFamily: `heading`,
}

const legendBallCellStyle = {
  ...baseCellStyle,
}

const legendExplanationCellStyle = {
  ...baseCellStyle,
}

const legendHeaderStyle = {
  ...baseCellStyle,
  color: `text.header`,
  fontSize: 2,
  fontWeight: `semiBold`,
  m: 0,
}

const balls = [
  <td sx={legendBallCellStyle} key={`${legendBallCellStyle}-2`}>
    <EvaluationCell num="3" style={legendBallStyle} />
  </td>,
  <td sx={legendBallCellStyle} key={`${legendBallCellStyle}-3`}>
    <EvaluationCell num="2" style={legendBallStyle} />
  </td>,
  <td sx={legendBallCellStyle} key={`${legendBallCellStyle}-4`}>
    <EvaluationCell num="1" style={legendBallStyle} />
  </td>,
  <td sx={legendBallCellStyle} key={`${legendBallCellStyle}-5`}>
    <EvaluationCell num="0" style={legendBallStyle} />
  </td>,
]

const legendText = [
  <td sx={legendExplanationCellStyle} key={`legendExplanationCell-2`}>
    {iconTextDictionary[3]}
  </td>,
  <td
    sx={legendExplanationCellStyle}
    key={`legendExplanalegendBallCellStyletionCell-3`}
  >
    {iconTextDictionary[2]}
  </td>,
  <td sx={legendExplanationCellStyle} key={`legendExplanationCell-4`}>
    {iconTextDictionary[1]}
  </td>,
  <td sx={legendExplanationCellStyle} key={`legendExplanationCell-5`}>
    {iconTextDictionary[0]}
  </td>,
]

export default function LegendTable() {
  return (
    <table sx={{ ...legendTableContainerStyle, width: `100%` }} id="legend">
      <caption sx={{ ...legendTableCaptionStyle }}>Legend</caption>
      <tbody
        sx={{
          display: [`none`, null, `table`],
          width: `100%`,
        }}
      >
        <tr>
          <th
            sx={{ ...legendBallCellStyle, ...legendHeaderStyle }}
            key={`${legendBallCellStyle}-1`}
            scope="row"
          >
            Icon
          </th>
          {balls}
        </tr>
        <tr>
          <th
            sx={{ ...legendExplanationCellStyle, ...legendHeaderStyle }}
            key={`legendExplanationCell-1`}
            scope="row"
          >
            Feature Availability
          </th>
          {legendText}
        </tr>
      </tbody>
      <tbody
        sx={{
          display: [`table`, null, `none`],
          width: `100%`,
          ...legendTableContainerStyle,
        }}
      >
        <tr>
          <th
            sx={{ ...legendBallCellStyle, ...legendHeaderStyle }}
            key={`${legendBallCellStyle}-1`}
            scope="col"
          >
            Icon
          </th>
          <th
            sx={{ ...legendExplanationCellStyle, ...legendHeaderStyle }}
            key={`legendExplanationCell-1`}
            scope="col"
          >
            Feature Availability
          </th>
        </tr>
        {[0, 1, 2, 3, 4].map(i => (
          <tr key={i}>
            {balls[i]}
            {legendText[i]}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
